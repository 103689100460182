import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import VideosMenu from "../components/videosMenu"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Videos = ({ data }) => {
  const videos = data.allYoutubeVideo.edges.map(x => (
    <div className="video-padding">
      <iframe
        title="youtube video"
        width="100%"
        height="100%"
        loading="lazy"
        src={`https://www.youtube-nocookie.com/embed/${x.node.videoId}`}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        className="lazyload"
      />
    </div>
  ))

  return (
    <Layout>
      <GatsbySeo
        title="Videos"
        titleTemplate="%s | Eduardo Ceballos"
        description="Videos"
        canonical="https://eduardoceballos.com/videos"
        openGraph={{
          url: `https://eduardoceballos.com/videos`,
          title: `Videos`,
          description: `Videos`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/videos.jpg`,
              alt: `Videos`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Videos</h1>
      <VideosMenu />
      <div className="videos-grid">{videos}</div>
    </Layout>
  )
}

export const query = graphql`
  {
    allYoutubeVideo(limit: 4) {
      edges {
        node {
          title
          videoId
        }
      }
    }
  }
`

export default Videos
